import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY,
  RELATED_WEBSITES,
  EDITED_RELATED_WEBSITE,
} from './constants';

const initialState = {
  [RELATED_WEBSITES]: null,
};

const reducers = {
  setRelatedWebsites: assignPayloadToKey(RELATED_WEBSITES),
  setEditedRelatedWebsite: assignPayloadToKey(EDITED_RELATED_WEBSITE),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
