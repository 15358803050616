export const KEY = 'user';

export const USER_INFO = 'userInfo';
export const USER_INFO_STATUS = 'userInfoStatus';

export const DELETE_ACCOUNT_STATUS = 'deleteAccountStatus';

export const UPDATE_PASSWORD_STATUS = 'updatePasswordStatus';
export const UPDATE_PASSWORD_QUERY_STATUS = 'updatePasswordQueryStatus';

export const UPDATE_USER_INFO_STATUS = 'updateUserInfoStatus';
export const UPDATE_USER_INFO_ERROR = 'updateUserInfoError';
