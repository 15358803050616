export const KEY = 'auth';

export const USER = 'user';
export const TOKEN = 'token';
export const REDIRECT = 'redirect';
export const FETCH_USER_LOADER = 'fetchUserLoader';
export const AUTHENTICATE_LOADER = 'authenticateLoader';
export const LOGOUT_LOADER = 'logoutLoader';
export const AUTHENTICATION_ERROR = 'authenticationError';

export const RESET_PASSWORD_STATUS = 'resetPasswordStatus';
export const RESET_PASSWORD_SUCCESS = 'resetPasswordSuccess';

export const REINIT_PASSWORD_CODE = 'reinitPasswordCode';
export const REINIT_PASSWORD_STATUS = 'reinitPasswordStatus';
export const REINIT_PASSWORD_QUERY_STATUS = 'reinitPasswordQueryStatus';
