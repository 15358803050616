export const KEY = 'sessions';

export const ORGANISATIONS = 'organisations';
export const ORGANISATIONS_LIST_STATUS = 'organisationsListStatus';

export const CREATE_SESSION_STATUS = 'createSessionStatus';

export const UPDATE_ORGANISATION_PUBLICATION_STATUS = 'updateOrganisationPublicationStatus';

export const PUBLISH_CONFIRM_OPEN = 'publishConfirmOpen';
