import {
  CaseReducer, createAsyncThunk, PayloadAction,
} from '@reduxjs/toolkit';

export const createAsyncAction = (
  sliceName: string,
  actionName:string,
  fn: (...args:any[])=>any,
) => createAsyncThunk(`${sliceName}/${actionName}`, fn);

export const getAsyncActionCases = (sliceName:string, actionName: string): string[] => ['pending', 'fulfilled']
  .map((key) => `${sliceName}/${actionName}/${key}`);

export const assignPayloadToKey = (key: string): CaseReducer<any, PayloadAction<any, any>> => (
  state,
  { payload },
) => {
// Use no param reassign as immutability is handled by redux toolkit
// eslint-disable-next-line no-param-reassign
  state[key] = payload;
};
