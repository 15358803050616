import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY,
  PARTNER_WEBSITES,
  EDITED_PARTNER_WEBSITE,
} from './constants';

const initialState = {
  [PARTNER_WEBSITES]: null,
};

const reducers = {
  setPartnerWebsites: assignPayloadToKey(PARTNER_WEBSITES),
  setEditedPartnerWebsite: assignPayloadToKey(EDITED_PARTNER_WEBSITE),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
