import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY, USER, REDIRECT, AUTHENTICATION_ERROR, TOKEN, RESET_PASSWORD_SUCCESS,
  REINIT_PASSWORD_STATUS, REINIT_PASSWORD_CODE,
} from './constants';

const initialState = {};

const reducers = {
  setUser: assignPayloadToKey(USER),
  setToken: assignPayloadToKey(TOKEN),
  setRedirect: assignPayloadToKey(REDIRECT),
  setAuthenticationError: assignPayloadToKey(AUTHENTICATION_ERROR),
  setResetPasswordSuccess: assignPayloadToKey(RESET_PASSWORD_SUCCESS),
  setReinitPasswordStatus: assignPayloadToKey(REINIT_PASSWORD_STATUS),
  setReinitPasswordCode: assignPayloadToKey(REINIT_PASSWORD_CODE),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
