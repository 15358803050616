import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY, NETWORK_ERROR,
} from './constants';

const initialState = {

};

const reducers = {
  setNetworkError: assignPayloadToKey(NETWORK_ERROR),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
