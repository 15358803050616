import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY,
  BUSINESS_FIELDS,
  EDITED_BUSINESS_FIELD,
} from './constants';

const initialState = {
  [BUSINESS_FIELDS]: null,
};

const reducers = {
  setBusinessFields: assignPayloadToKey(BUSINESS_FIELDS),
  setEditedBusinessField: assignPayloadToKey(EDITED_BUSINESS_FIELD),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
