import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY, ORGANISATIONS, PUBLISH_CONFIRM_OPEN,
} from './constants';

const initialState = {
  [ORGANISATIONS]: null,
  [PUBLISH_CONFIRM_OPEN]: false,
};

const reducers = {
  setOrganisations: assignPayloadToKey(ORGANISATIONS),
  setPublishConfirmOpen: assignPayloadToKey(PUBLISH_CONFIRM_OPEN),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
