export const KEY = 'admin';

export const OWNER_LINK = 'ownerLink';
export const OWNER_LINK_LOADER = 'ownerLinkLoader';

export const PENDING_USERS = 'pendingUsers';
export const PENDING_USERS_STATUS = 'pendingUsersStatus';

export const PENDING_ORGANISATIONS = 'pendingOrganisations';
export const PENDING_ORGANISATIONS_STATUS = 'pendingOrganisationsStatus';

export const NO_OWNER_ORGANISATIONS = 'adminOrganisations';
export const ADMIN_ORGANISATIONS_STATUS = 'noOwnerOrganisationsStatus';

export const USERS = 'users';
export const FETCH_USERS_STATUS = 'fetchUsersStatus';

export const ORGANISATION_DRAFT = 'organisationDraft';
export const ORGANISATION_DRAFT_STATUS = 'organisationDraftStatus';

export const UPDATE_SIGNUP_STATUS = 'updateSignUpStatus';
export const GENERATE_QUERY_STATUS = 'generateQueryStatus';

export const UPDATE_DRAFT_STATUS = 'updateDraftStatus';

export const INVITE_OWNER_INFO = 'inviteOwnerInfo';

export const INVITE_OWNER_STATUS = 'inviteOwnerStatus';

export const UPDATE_ORGANISATION_PUBLICATION_STATUS = 'updateOrganisationPublicationStatus';

export const ORGANISATIONS_EXPANDED = 'organisationsExpanded';

export const UPDATE_USER_STATUS = 'updateUserStatus';

export const USER_UPDATE_CONFIRM_OPEN = 'userUpdateConfirmOpen';
