import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import logger from 'js-logger';
import AppRouter from 'routes/AppRouter';
import { history } from 'routes';
import * as serviceWorker from './serviceWorker';
import App from './App';
import store, { persistor } from './store';
import 'i18n';

logger.useDefaults({ defaultLevel: logger.INFO });

declare global {
  interface Window { CONFIG: any; ERROR_HANDLER: (status: number) => any}
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouter history={history}>
          <App />
        </AppRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
