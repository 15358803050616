import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY, FETCH_STATUS, NOTIFICATION,
} from './constants';

const initialState = {
  [FETCH_STATUS]: {},
  [NOTIFICATION]: null,
};

const reducers = {
  setFetchStatus: (state, { payload: { id, status } }) => {
    state[FETCH_STATUS][id] = status;
  },
  setNotification: assignPayloadToKey(NOTIFICATION),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
