export const KEY = 'signUp';

export const SIGN_UP_CODE = 'signUpCode';
export const SIGN_UP_DATA = 'signUpData';
export const SIGN_UP_DATA_STATUS = 'signUpDataStatus';
export const SIGN_UP_PROCESS_STATUS = 'signUpProcessStatus';
export const SIGN_UP_PROCESS_WITH_ACCOUNT_STATUS = 'signUpProcessWithAccountStatus';
export const SIGN_UP_ERROR = 'signUpError';

export const SIGN_UP_STATUS = 'signUpStatus';

export const SIGN_UP_EMAIL = 'signUpEmail';
export const CHECK_EMAIL_STATUS = 'checkEmailStatus';
export const SIGN_UP_REDIRECT = 'signUpRedirect';
