import { Route, Routes } from 'react-router-dom';

const getRoute = ({ component: Component, children, ...props }) => (Component
  ? (
    <Route key={props.name} element={<Component />} {...props}>
      {children?.map((route) => getRoute(route))}
    </Route>
  )
  : null);

interface Props {
  routes: Array<any>
}

export default function RoutesBuilder({ routes }: Props) {
  return (
    <Routes>
      {routes.map((route) => getRoute(route))}
    </Routes>
  );
}
