import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import { UpdatePasswordStatus } from '../types';
import {
  KEY,
  USER_INFO, UPDATE_PASSWORD_STATUS, UPDATE_USER_INFO_ERROR,
} from './constants';

const initialState = {
  [USER_INFO]: {},
  [UPDATE_PASSWORD_STATUS]: UpdatePasswordStatus.IDLE,
};

const reducers = {
  setUserInfo: assignPayloadToKey(USER_INFO),
  setUpdatePasswordStatus: assignPayloadToKey(UPDATE_PASSWORD_STATUS),
  setUpdateUserInfoError: assignPayloadToKey(UPDATE_USER_INFO_ERROR),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
