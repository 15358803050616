import RoutesBuilder from 'components/RoutesBuilder';
import routes from 'routes';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Suspense } from 'react';
import LayoutLoading from 'pages/ManageLayout/LayoutLoading';
import appTheme from './appTheme';

function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <Suspense fallback={<LayoutLoading open />}>
        <RoutesBuilder routes={routes} />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
