import { createTheme, ThemeOptions } from '@mui/material/styles';

export function hexToRgba(hexColor: string, opacity: number) {
  return `rgba(${hexColor.substring(1, hexColor.length).match(/.{1,2}/g).map((chunk) => parseInt(chunk, 16)).join(',')},${opacity})`;
}

export const colors = {
  background: hexToRgba('#242424', 0.98),
  primary1: '#009DDC',
  primary2: '#EE3224',
};

/*

            {/* <Box component="span" sx={{ color: '#EE3224', fontSize: 80 }}>Oasis</Box>
            <Box component="span" sx={{ color: '#009DDC' }}>Forum</Box>
            */

/*
export const colors = {
  background: '#242424',
  primary1: '#00f5c9',
  primary2: '#FFB74D',
};
*/

export const themeOptions: ThemeOptions = {

  palette: {
    primary: {
      main: '#13759e',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#EBF4FF',
    },
  },
  shape: {
    borderRadius: 1,
  },
  props: {
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: 'small',
    },
  },
  typography: {
    fontFamily: 'Quicksand',
    fontSize: 15,
    subtitle2: {
      lineHeight: 1.43,
    },
  },

  /*
  palette: {
    primary: {
      main: '#13759e',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#e4f5f5',
    },
  },
  shape: {
    borderRadius: 1,
  },
  typography: {
    fontFamily: 'Source Sans Pro',
    subtitle2: {
      color: '#717275',
    },
  },
  */
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    // @ts-ignore
    MUIRichTextEditor: {},
    MuiImageListItemBar: {
      styleOverrides: {
        subtitle: {
          // marginTop: '.3rem',
        },
      },
    },
  },
  overrides: {
    MUIRichTextEditor: {
      editor: {
        overflow: 'auto',
        borderBottom: '1px solid gray',
      },
      root: {
        marginTop: 10,
      },
      placeHolder: {
        minHeight: '5rem',
      },
    },
  },
};

export default createTheme(themeOptions);
