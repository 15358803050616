import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY, OWNER_LINK, PENDING_USERS, USERS, PENDING_ORGANISATIONS,
  ORGANISATION_DRAFT, NO_OWNER_ORGANISATIONS, INVITE_OWNER_INFO, ORGANISATIONS_EXPANDED,
  USER_UPDATE_CONFIRM_OPEN,
} from './constants';

const initialState = {
  [OWNER_LINK]: null,
  [PENDING_USERS]: null,
  [PENDING_ORGANISATIONS]: null,
  [NO_OWNER_ORGANISATIONS]: null,
  [ORGANISATION_DRAFT]: null,
  [INVITE_OWNER_INFO]: false,
  [ORGANISATIONS_EXPANDED]: true,
  [USER_UPDATE_CONFIRM_OPEN]: false,
};

const reducers = {
  setOwnerLink: assignPayloadToKey(OWNER_LINK),
  setPendingUsers: assignPayloadToKey(PENDING_USERS),
  setPendingOrganisations: assignPayloadToKey(PENDING_ORGANISATIONS),
  setUsers: assignPayloadToKey(USERS),
  setOrganisationDraft: assignPayloadToKey(ORGANISATION_DRAFT),
  setAdminOrganisations: assignPayloadToKey(NO_OWNER_ORGANISATIONS),
  setInviteOwnerInfo: assignPayloadToKey(INVITE_OWNER_INFO),
  setOrganisationsExpanded: assignPayloadToKey(ORGANISATIONS_EXPANDED),
  setUserUpdateConfirmOpen: assignPayloadToKey(USER_UPDATE_CONFIRM_OPEN),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
