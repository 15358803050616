import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY,
  SIGN_UP_DATA, SIGN_UP_CODE, SIGN_UP_STATUS, SIGN_UP_ERROR, SIGN_UP_EMAIL, SIGN_UP_REDIRECT,
} from './constants';

const initialState = {};

const reducers = {
  setSignUpData: assignPayloadToKey(SIGN_UP_DATA),
  setSignUpCode: assignPayloadToKey(SIGN_UP_CODE),

  setSignUpStatus: assignPayloadToKey(SIGN_UP_STATUS),
  setSignUpError: assignPayloadToKey(SIGN_UP_ERROR),
  setSignUpEmail: assignPayloadToKey(SIGN_UP_EMAIL),
  setSignUpRedirect: assignPayloadToKey(SIGN_UP_REDIRECT),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
